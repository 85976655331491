import { Injectable, Injector, NgZone } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthResponse, TLoginResponse } from './auth-response';
import { AuthData } from '../../models/auth-data';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EnvironmentConfig } from '../../environment-config';
import { ErrorReporter } from '../error-reporter';
import { User, UserRaw } from '../../models/user';
import { AuthStateActions } from '../../state/auth.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';

/**
 * Authentication service.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    protected currentToken: string = null;

    /**
     * Initialize authentication.
     */
    async init(): Promise<AuthData> {
        return this.getUserDetails()
            .pipe(
                switchMap(result => {
                    return of({
                        user: AuthResponse.toUser(result),
                    });
                })
            )
            .toPromise();
    }

    constructor(
        private router: Router,
        private readonly ngZone: NgZone,
        private readonly http: HttpClient,
        private readonly env: EnvironmentConfig,
        private readonly errorReporter: ErrorReporter,
        private readonly injector: Injector,
        private readonly store$: Store<AppState>
    ) {}

    login(email: string, password: string): Observable<AuthResponse> {
        const params = { email, password };
        return this.http.post<TLoginResponse>(`${this.env.backend}/auth/login`, params).pipe(
            map((response: TLoginResponse) => {
                const token = response.token;
                this.setAccessToken(token);
            }),
            switchMap(() => this.getUserDetails()),
            tap(value => {
                this.store$.dispatch(
                    AuthStateActions.loginCompleted({
                        user: value.user,
                    })
                );
                this.router.navigate(['/dashboard']);
            }),
            catchError(error => {
                this.errorReporter.reportError(error);
                throw error;
            })
        );
    }
    /**
     * Perform login call to backend.
     */
    private getUserDetails(): Observable<AuthResponse> {
        return this.http.get<UserRaw>(`${this.env.backend}/auth/profile`).pipe(
            switchMap(user => {
                const authResponse = new AuthResponse();
                authResponse.user = User.fromRaw(user);
                return of(authResponse);
            })
        );
    }

    getAccessToken(): string {
        return this.currentToken;
    }

    setAccessToken(token: string) {
        this.currentToken = token;
    }

    /**
     * Perform logout call to backend.
     */
    private logoutFromBackend(): Observable<Response> {
        return this.http.post<Response>(`${this.env.backend}/auth/logout`, {});
    }

    redirectToLoginPage() {
        this.store$.dispatch(AuthStateActions.logoutCompleted());
        this.router.navigate(['/signin']);
    }

    refreshAccessToken(): Observable<any> {
        return this.http.post<TLoginResponse>(`${this.env.backend}/auth/refresh-token`, {});
    }

    logout(): Observable<any> {
        // Backend endpoint require user to be logged, so first we hit backend and then no matter what we got from
        // backend, we sign out user from frontend.
        return this.logoutFromBackend().pipe(
            catchError(e => {
                this.setAccessToken(null);
                this.redirectToLoginPage();
                return e;
            }),
            tap(() => {
                this.setAccessToken(null);
                this.redirectToLoginPage();
            })
        );
    }

    forgetPassword(mail: string): Observable<any> {
        return this.http.post<Response>(`${this.env.backend}/auth/forget-password`, {mail}); 
    }
}
